<template>
	<MainLayout>
		<div class="w-full">
			<div class="flex items-center w-full mb-6 title my-11">
				<div v-if="$route.params.id" class="flex items-center">
					<span class="text-2xl font-bold cursor-pointer icon-arrow-left normal text-gray"
						@click="cancelProject"></span>
				</div>
				<div v-else>
					<span class="text-2xl font-bold cursor-pointer icon-arrow-left normal text-gray"
						@click="changeIsOpen(true, 'modalCancel')"></span>
					<ModalConfirm 
						title="Batalkan Pengajuan?" 
						message="Apakah Anda Yakin Tidak Melanjutkan Pengajuan Request Ini?" 
						icon="rejected" textButton="Cancel Project" 
						:isOpen="isOpenModalCancelProject" 
						@changeIsOpen="changeIsOpen($event, 'modalCancel')" 
						@click="cancelProject"
						colorButton="error"></ModalConfirm>
				</div>
				<h4 class="pl-3 text-3xl font-semibold text-gray-darkest">{{$route.name === editRfp? 'Edit Project':'Create New Project'}}</h4>
			</div>
			<div class="w-full mb-10 bg-white dashboard rounded-2xl shadow-gray-sm">
				<ValidationObserver ref="formRfp">
					<div class="flex justify-end px-6 pt-6">
						<p class="underline cursor-pointer text-tertiary hover:text-tertiary-dark"
							@click="expandCollapseAll">{{ messageCollapseAll }}</p>
					</div>
					<ProposalInfomation @collapse="changeCollapse($event, 'proposalInformation')" />

					<ProjectInformation @collapse="changeCollapse($event, 'projectInformation')" />

					<ProjectRequirement @collapse="changeCollapse($event, 'projectRequirement')" 
						@changeLoadingFile="changeLoadingFile" 
						:loadingfile1="loadingFileProjectRequirement.loadingfile1" 
						:loadingfile2="loadingFileProjectRequirement.loadingfile2"
						:loadingfile3="loadingFileProjectRequirement.loadingfile3"/>

					<VendorCandidate @collapse="changeCollapse($event, 'vendorCandidate')" />

					<ScoringInformation @collapse="changeCollapse($event, 'scoringTeknis')" ref="scoring"/>
					<div class="flex flex-wrap-reverse w-full p-6">
						<div class="flex-none w-full sm:flex-1 sm:w-max">
							<ButtonGista 
								v-if="$route.name !== editRfp"
								type="secondary"
								color="primary"
								customClass="w-full sm:w-60 mt-3.5 sm:mt-0"
								@click="changeIsOpen(true, 'modalSaveAsDraft')"
								:disabled="loadingFileProjectRequirement.loadingfile1 || loadingFileProjectRequirement.loadingfile2 || loadingFileProjectRequirement.loadingfile3">
								Save As Draft
							</ButtonGista>
						</div>
						<div class="flex-none w-full sm:w-max">
							<ButtonGista
								v-if="menuRFP?.canCreate"
								type="primary"
								@click="changeIsOpen(true, 'modalSubmit')"
								customClass="w-full sm:w-60"
								:disabled="loadingFileProjectRequirement.loadingfile1 || loadingFileProjectRequirement.loadingfile2 || loadingFileProjectRequirement.loadingfile3"
								>{{$route.name === editRfp?'Save Changes':'Submit Project'}}
							</ButtonGista>
						</div>
					</div>
					<ModalConfirm 
						title="Simpan sebagai Draft" 
						message="Anda dapat melanjutkan proyek di tab ‘Draft’" 
						icon="saved" textButton="Save As Draft" 
						:isOpen="isOpenModalSaveAsDraft" 
						@changeIsOpen="changeIsOpen($event, 'modalSaveAsDraft')" 
						@click="saveAsDraft"
						typeButton="secondary">
					</ModalConfirm>
					<ModalConfirm 
						title="Apakah Anda Yakin?" 
						:message="`Pastikan seluruh informasi sudah benar sebelum ${$route.name === editRfp?'save':'submit'}`" 
						icon="submitted" 
						:textButton="$route.name === editRfp?'Save Changes':'Submit Project'" 
						:isOpen="isOpenModalSubmit" 
						@changeIsOpen="changeIsOpen($event, 'modalSubmit')" 
						@click="submitRFP">
					</ModalConfirm>
				</ValidationObserver>
			</div>
		</div>
	</MainLayout>
</template>

<script>
import ProposalInfomation from '@/modules/module-rfp/pages/createNewProject/ProposalInfomation.vue'
import ProjectInformation from '@/modules/module-rfp/pages/createNewProject/ProjectInformation.vue'
import ProjectRequirement from '@/modules/module-rfp/pages/createNewProject/ProjectRequirement.vue'
import VendorCandidate from '@/modules/module-rfp/pages/createNewProject/VendorCandidate.vue'
import ScoringInformation from '@/modules/module-rfp/pages/createNewProject/ScoringInformation.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_PROJECT_REQUIREMENT, MESSAGE_POPUP_VALIDATION_SAVE_AS_DRAFT,
	MESSAGE_POPUP_VALIDATION_SUBMIT, } from '@/core/constant/errorMessage.js'
import { MESSAGE_SUCCESS_SAVE_AS_DRAFT, MESSAGE_SUCCESS_SUBMIT } from '@/core/constant/successMessage'
import { PATH_ROOT, EDITRFP, PROJECT_DETAIL } from '@/core/constant/routeName'
import { SOLUTIP_OPR_KP } from '@/core/constant/applicationRoleName.js'

export default {
	name: 'RFP',
	data() {
		return {
			editRfp: EDITRFP,
			isOpenModalCancelProject: false,
			isOpenModalSaveAsDraft: false,
			isOpenModalSubmit: false,
			messageCollapseAll: 'Collapse All',
			keyScoringTeknis: ['vendorData', 'solution', 'support', 'price'],
			loadingFileProjectRequirement: {
				loadingfile1: false,
				loadingfile2: false,
				loadingfile3: false,
			}
		}
	},
	computed: {
		//state rfp
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		menuRFP() {
			return this.$store.getters['dashboard/menuRFP']
		},
		approverUkkp() {
			return this.$store.state.rfp.approverUkkp
		},
		collapse: {
			get() {
				return this.$store.state.rfp.collapse
			},
			set(value) {
				this.$store.commit('rfp/SET_COLLAPSE', value)
			}
		},
		proposalInformation: {
			get() {
				return this.$store.state.rfp.proposalInformation
			},
			set(value) {
				this.$store.commit('rfp/SET_PROPOSAL_INFORMATION', value)
			},
		},
		projectInformation: {
			get() {
				return this.$store.state.rfp.projectInformation
			},
			set(value) {
				this.$store.commit('rfp/SET_PROJECT_INFORMATION', value)
			},
		},
		projectRequirement: {
			get() {
				return this.$store.state.rfp.projectRequirement
			},
			set(value) {
				this.$store.commit('rfp/SET_PROJECT_REQUIREMENT', value)
			},
		},
		vendorCandidate() {
			return this.$store.state.rfp.vendorCandidate
		},
		scoringTeknisCriteria: {
			get() {
				return this.$store.state.rfp.scoringTeknisCriteria
			},
			set(value) {
				this.$store.commit('rfp/SET_SCORING_TEKNIS_CRITERIA', value)
			}
		},

		//custom error state
		errorProposalInformation: {
			get() {
				return this.$store.state.errorRfp.errorProposalInformation
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROPOSAL_INFORMATION', value)
			}
		},
		errorProjectInformation: {
			get() {
				return this.$store.state.errorRfp.errorProjectInformation
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_INFORMATION', value)
			},
		},
		errorProjectRequirement: {
			get() {
				return this.$store.state.errorRfp.errorProjectRequirement
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_REQUIREMENT', value)
			}
		},
		errorScoringInformationBobotPrice: {
			get() {
				return this.$store.state.errorRfp.errorScoringInformationBobotPrice
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_SCORING_INFORMATION_BOBOT_PRICE', value)
			}
		},
		errorScoringInformationBobotTotal: {
			get() {
				return this.$store.state.errorRfp.errorScoringInformationBobotTotal
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_SCORING_INFORMATION_BOBOT_TOTAL', value)
			}
		},
		menuActionSubmit() {
			return this.$store.getters['dashboard/menuRfpActionSubmit']
		},
		activeButton() {
			return this.$store.state.projectLog.activeButton
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
	},
	methods: {
		async saveAsDraft() {
			try {
				//show loading
				this.modal.modalLoading = true
				// custom check error save as draft
				await this.$store.dispatch('errorRfp/resetState', { saveAsDraft: true })
				const success = await this.$refs.formRfp.validate()
				const resErrorProposalInformation = this.$store.dispatch('errorRfp/checkErrorProposalInformationDraft')
				const resErrorProjectInformation = this.$store.dispatch('errorRfp/checkErrorProjectInformation', { isSubmit: false })
				const resErrorProjectRequirement = this.$store.dispatch('errorRfp/checkErrorProjectRequirement', { isSubmit: false })
				const resErrorVendorCandidateVendorName = this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
				const resErrorScoringInformationDraft = this.$store.dispatch('errorRfp/checkErrorScoringInformationDraft', { routeName: this.$route.name })
				const [ errorProposalInformation, errorProjectInformation, errorProjectRequirement, errorVendorCandidateVendorName, errorScoringInformationDraft]
				 = await Promise.all([resErrorProposalInformation, resErrorProjectInformation, resErrorProjectRequirement, resErrorVendorCandidateVendorName, resErrorScoringInformationDraft ])
				//true means no error and false error
				console.log({success}, {errorProposalInformation}, {errorProjectInformation}, {errorProjectRequirement}, {errorVendorCandidateVendorName}, {errorScoringInformationDraft}, 'save as draft error');
				if (!success || !errorProposalInformation || !errorProjectInformation || !errorProjectRequirement || !errorVendorCandidateVendorName || !errorScoringInformationDraft) {
					//loading out
					console.log(this.$refs.formRfp.errors, 'errors');
					this.modal.modalLoading = false
					//show modal error rfp validate
					this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SAVE_AS_DRAFT }
					return
				}
				
				/**start save as draft*/
				const response = await this.$store.dispatch(`${this.$route.params.id? 'rfpSubmit/putSaveAsDraft' : 'rfpSubmit/postSaveAsDraft'}`, this.$route.params.id || null)
				if (response?.status < 300 && response?.status >= 200) {
					/** end save as draft*/
					setTimeout(async() => {
						const query = { project: 'draft' }
											this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
					}, 200)
					//reset staterfp store
					await this.$store.dispatch('rfp/resetState')
					await this.$store.dispatch('dashboard/resetFilterProject')
					//loading out
					this.modal.modalLoading = false
					//modal success save as draft
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SAVE_AS_DRAFT }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				//close loading when error
				this.modal.modalLoading = false
				//show modal error
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		async submitRFP() {
			//show loading
			this.modal.modalLoading = true
			//validate with vee-validate
			const success = await this.$refs.formRfp.validate()
			//called set error function in child scoring
			this.$refs.scoring.checkErrorSubmit()
			
			const resErrorProposalInformation = this.$store.dispatch('errorRfp/checkErrorProposalInformationSubmit', { roleOprKp: this.menuRFP?.applicationRoleName === SOLUTIP_OPR_KP })
			//custom check error validate project requirement
			const resErrorProjectInformation = this.$store.dispatch('errorRfp/checkErrorProjectInformation', { isSubmit: true })
			// //custom check error validate project requirement
			const resErrorProjectRequirement = this.checkErrorProjectRequirement()
			const resErrorProjectRequirement1 = this.$store.dispatch('errorRfp/checkErrorProjectRequirement', { isSubmit: true })
			// //custom error validate vendor candidate
			const resErrorVendorCandidateField = this.$store.dispatch('errorRfp/checkErrorVendorCandidateField')
			const resErrorVendorCandidateVendorName = this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
			// //custom error validate scoring teknis
			const resErrorScoringInformationSubmit = this.$store.dispatch('errorRfp/checkErrorScoringInformationSubmit', { routeName: this.$route.name })
			
			const arrResCheckError = [ resErrorProposalInformation, resErrorProjectInformation, resErrorProjectRequirement, resErrorProjectRequirement1, resErrorVendorCandidateField, resErrorVendorCandidateVendorName, resErrorScoringInformationSubmit ]
			const [ 
				errorProposalInformation, 
				errorProjectInformation, 
				errorProjectRequirement, 
				errorProjectRequirement1, 
				errorVendorCandidateField, 
				errorVendorCandidateVendorName, 
				errorScoringInformationSubmit] = await Promise.all(arrResCheckError)
				console.log({success}, {errorProposalInformation}, {errorProjectInformation}, {errorProjectRequirement}, {errorProjectRequirement1}, {errorVendorCandidateField}, {errorVendorCandidateVendorName}, {errorScoringInformationSubmit}, 'error submit rfp');
				
				// const [errorProjectInformation, errorProjectRequirement, errorVendorCandidate, errorScoringInformation] = await Promise.all([resErrorProjectInformation, resErrorProjectRequirement, resErrorVendorCandidate, resErrorScoringInformation])
				//true means no error and false error
				if (!success || !errorProposalInformation || !errorProjectInformation || !errorProjectRequirement || !errorProjectRequirement1 || !errorVendorCandidateField || !errorVendorCandidateVendorName || !errorScoringInformationSubmit) {
					//loading out
					this.modal.modalLoading = false
					console.log(this.$refs.formRfp.errors, 'errors');

					//show modal error rfp validate
					this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
					return
				}
				//success validate form
				try {
					
					/** start submit project */
				const response = await this.$store.dispatch(`${this.$route.params.id? 'rfpSubmit/putSubmitProject' : 'rfpSubmit/postSubmitProject'}`, this.$route.params.id || null)
				if (response?.status < 300 && response?.status >= 200) {
					await this.$store.dispatch('rfp/resetState')
					await this.$store.dispatch('dashboard/resetFilterProject')
					this.modal.modalLoading = false
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SUBMIT }
					
					/** end submit project */
					//if create new project
					console.log(this.$route.name, EDITRFP, 'edit rfp');
					if (this.$route.name === EDITRFP) {
						await this.$router.push(`/${PATH_ROOT}/${PROJECT_DETAIL}/${this.$route.params.id}`).catch(() => ({}))
					} else {
						await this.$router.push({ path: `/${PATH_ROOT}`, query: { project: 'outstanding' } }).catch(() => ({}))
					}
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//close loading when error
				this.modal.modalLoading = false
				//show modal error
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		//custom validation error project information 14 enter
		checkErrorProjectInformation() {
			return Object.values(this.errorProjectInformation).every(e => !e.value)
		},
		//custom validation error project requirement
		checkErrorProjectRequirement() {
			const error = Object.values(this.projectRequirement).filter(e => typeof e === 'string').every(e => !e)
			if (error) {
				this.errorProjectRequirement = { value: true, message: MESSAGE_PROJECT_REQUIREMENT, counter: 0 }
			} else {
				this.errorProjectRequirement = { value: false, message: '', counter: 1 }
			}
			return !error
		},

		//function collapse/extend
		changeCollapse(e, key) {
			this.collapse[key] = !this.collapse[key]
			console.log(this.collapse);
			if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
			else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
		},
		expandCollapseAll() {
			for (const key in this.collapse) {
				if (this.messageCollapseAll === 'Extend All') {
					this.collapse[key] = true
				} else {
					this.collapse[key] = false
				}
			}
			if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
			else this.messageCollapseAll = 'Collapse All'
		},
		// back arrow cancel project clean state
		async cancelProject() {
			setTimeout(() => {
				this.$router.go(-1)
			}, 200)
		},
		changeLoadingFile(e) {
			const { name, value } = e
			this.loadingFileProjectRequirement[name] = value
		},
		changeIsOpen(val, key) {
			if (key === 'modalCancel') {
				this.isOpenModalCancelProject = val
			}
			if (key === 'modalSaveAsDraft') {
				this.isOpenModalSaveAsDraft = val
			}
			if (key === 'modalSubmit') {
				this.isOpenModalSubmit = val
			}
		}
	},
	async mounted() {
		const res1 = this.$store.dispatch('dashboard/getUserDetail')
		//get detail rfp
		const res2 = this.$store.dispatch('rfp/getScoringTeknisCriteria')
		const res3 = this.$store.dispatch('dashboard/getMenu')
		await Promise.all([res1, res2, res3])
		if (!this.menuRFP?.canCreate) {
			this.$store.commit('projectLog/SET_CAN_ACCESS', false)
			return;
		}
		if (this.$route.params.id) {
			//if continue draft there is param id so we trying to get all data with rfp id
			const res4 = this.$store.dispatch('rfp/getProposalInformation', this.$route.params.id)
			const res5 = this.$store.dispatch('rfp/getProjectInformation', this.$route.params.id)
			const res6 = this.$store.dispatch('rfp/getProjectRequirement', this.$route.params.id)
			const res7 = this.$store.dispatch('rfp/getProjectVendorCandidate', this.$route.params.id)
			const res8 = this.$store.dispatch('rfp/getProjectScoringInformation', this.$route.params.id)
			const res9 = this.$store.dispatch('projectDetail/getProjectScoringInformation', this.$route.params.id)
			const res10 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.id)
			await Promise.all([res4, res5, res6, res7, res8, res9, res10])

		} else {
			//set technical coordinator information from user detail for create new project

			this.proposalInformation.technicalCoordinatorName = this.userProfile.name
			this.proposalInformation.technicalCoordinatorEmail = this.userProfile.email
			this.proposalInformation.technicalCoordinatorId = this.userProfile.username

		}

	},
	destroyed() {
		this.$store.dispatch('rfp/resetState')
		this.$store.dispatch('errorRfp/resetState', { saveAsDraft: false })
	},
	components: {
		ProposalInfomation,
		ProjectInformation,
		ProjectRequirement,
		VendorCandidate,
		ScoringInformation,
		ModalConfirm
	},
}
</script>
